header {
  color: white;
  padding: 10px;
  background-color: #cccc;
  text-align: center;
}


.deleteBtn {
  float: right;
  background-color: #ff0000;
  color: white;
  border-radius: 50%;
  border: none;
  padding: 5px 8px;
  cursor: pointer;
}

.addForm {
  display: flex;
}

.btn {
  flex: 1;
  display: inline-block;
  border: none;
  background-color: #555;
  color: #fff;
  padding: 7px 20px;
  cursor: pointer;
}

.inputAdd {
  flex: 10;
}

.container {
  padding: 6px;
}
